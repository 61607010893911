import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { HomeComponent } from './home.component';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { MenuAdminComponent } from '../menu-admin/menu-admin.component';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { UsersComponent } from 'src/app/pages/users/users.component';
import { AddCompanyComponent } from 'src/app/pages/add-company/add-company.component';
import { AddProjectComponent } from 'src/app/pages/add-project/add-project.component';
import { CatCtasComponent } from 'src/app/pages/cat-ctas/cat-ctas.component';
import { PremissionsComponent } from 'src/app/pages/premissions/premissions.component';
import { ProjectsComponent } from 'src/app/pages/projects/projects.component';
import { SlidebarComponent } from 'src/app/common/components/slidebar/slidebar.component';
import { ProjectConfigComponent } from 'src/app/pages/project-config/project-config.component';
import { BudgetComponent } from 'src/app/pages/budget/budget.component';
import { DealMemoComponent } from 'src/app/pages/deal-memo/deal-memo.component';
import { PurchaseOrderComponent } from 'src/app/pages/purchase-order/purchase-order.component';
import { RequestComponent } from 'src/app/pages/request/request.component';
import { PriorRequestComponent } from 'src/app/pages/prior-request/prior-request.component';
import { CheckRequestComponent } from 'src/app/pages/check-request/check-request.component';
import { ProvidersComponent } from 'src/app/pages/providers/providers.component';
import { CostReportComponent } from 'src/app/pages/cost-report/cost-report.component';
import { CtasProjectComponent } from 'src/app/pages/ctas-project/ctas-project.component';
import { ChecksReportComponent } from 'src/app/pages/checks-report/checks-report.component';
import { ConsultDealMemoComponent } from 'src/app/pages/consult-deal-memo/consult-deal-memo.component';
import { RequestVersion1Component } from 'src/app/pages/request-version1/request-version1.component';
import { DisponibleComponent } from 'src/app/pages/disponible/disponible.component';
import { EstimadoCierreComponent } from 'src/app/pages/estimado-cierre/estimado-cierre.component';
import { RequestMassiveComponent } from 'src/app/pages/request-massive/request-massive.component';
import { CheckDealMemoComponent } from 'src/app/pages/check-deal-memo/check-deal-memo.component';
import { DealMemoReportComponent } from 'src/app/pages/deal-memo-report/deal-memo-report.component';
import { TareasComponent } from 'src/app/pages/tareas/tareas.component';
import { ComprobacionComponent } from 'src/app/pages/comprobacion/comprobacion.component';
import { ImportadorComponent } from 'src/app/website/importador/importador.component';
import { PublicUsersComponent } from 'src/app/pages/public-users/public-users.component';
import { DepartamentosComponent } from 'src/app/pages/departamentos/departamentos.component';
import { SolGxcComponent } from 'src/app/pages/sol-gxc/sol-gxc.component';
import { DealMemoMasiveComponent } from 'src/app/pages/deal-memo-masive/deal-memo-masive.component';
import { ApproveRequestComponent } from 'src/app/pages/approve-request/approve-request.component';
import { CheckComprobacionComponent } from 'src/app/pages/check-comprobacion/check-comprobacion.component';
import { Reembolso2Component } from 'src/app/pages/reembolso/reembolso.component';
import { ConsultOrdenCompraComponent } from 'src/app/pages/consult-orden-compra/consult-orden-compra.component';
import { RequestAprovedComponent } from 'src/app/pages/request-aproved/request-aproved.component';
import { ChecksComponent } from 'src/app/website/checks/checks.component';
import { ComprobacionesComponent } from 'src/app/website/comprobaciones/comprobaciones.component';
import { GxcComponent } from 'src/app/website/gxc/gxc.component';
import { DealMemoPublicComponent } from 'src/app/website/deal-memo-public/deal-memo-public.component';
import { ReembolsoComponent } from 'src/app/website/reembolso/reembolso.component';
import { AproveProvidersComponent } from 'src/app/pages/aprove-providers/aprove-providers.component';
import { ProviderComponent } from 'src/app/pages/extSuppliers/provider/provider.component';
import { FilesComponent } from 'src/app/pages/extSuppliers/files/files.component';
import { UnrelatedProviderComponent } from 'src/app/pages/unrelated-provider/unrelated-provider.component';
import { RequestAnexoComponent } from 'src/app/pages/request-anexo/request-anexo.component';
import { SolAnexoComponent } from 'src/app/pages/sol-anexo/sol-anexo.component';
import { WelcomeComponent } from 'src/app/pages/welcome/welcome.component';
import { AddAnexosComponent } from 'src/app/pages/add-anexos/add-anexos.component';
import { EquiposComponent } from 'src/app/dispositivos/equipos/equipos.component';
import { EquipoComponent } from 'src/app/dispositivos/equipo/equipo.component';
import { PurchaseOrder1Component } from 'src/app/pages/purchase-order1/purchase-order1.component';
import { SolV1Component } from 'src/app/pages/sol-v1/sol-v1.component';
import { PoGlobalComponent } from 'src/app/pages/po-global/po-global.component';
import { PoGxcComponent } from 'src/app/pages/po-gxc/po-gxc.component';
import { SolRembolsoComponent } from 'src/app/pages/sol-rembolso/sol-rembolso.component';
import { ConsultPO1Component } from 'src/app/pages/consult-po1/consult-po1.component';
import { SolGxcv2Component } from 'src/app/pages/sol-gxcv2/sol-gxcv2.component';
import { ConsultSolComponent } from 'src/app/pages/consult-sol/consult-sol.component';
import { PoReportComponent } from 'src/app/pages/po-report/po-report.component';
import { AvailableComponent } from 'src/app/pages/available/available.component';
import { SolGralComponent } from 'src/app/pages/sol-gral/sol-gral.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'menuAdmin',
        component: MenuAdminComponent,
        children: [
          {
            path: '',
            component: MenuComponent,
            data: { title: 'Administracion' },
          },
          {
            path: 'users',
            component: UsersComponent,
          },
          {
            path: 'addCompany',
            component: AddCompanyComponent,
          },
          {
            path: 'addCompany/:id',
            component: AddProjectComponent,
          },
          {
            path: 'catCtas',
            component: CatCtasComponent,
          },
          {
            path: 'premissions',
            component: PremissionsComponent,
          },
          {
            path: 'publicAccess',
            component: PublicUsersComponent,
          },
          {
            path: 'equipos',
            component: EquiposComponent,
          },
          {
            path: 'equipo',
            component: EquipoComponent,
          },
        ],
      },
      {
        path: ':id',
        component: ProjectsComponent,
      },
      {
        path: ':id/task',
        component: TareasComponent,
      },
      {
        path: ':id/addProject',
        component: AddProjectComponent,
      },
      {
        path: ':id/project/:id',
        component: SlidebarComponent,
        canActivateChild: [AuthGuard],
        children: [
          {
            path: 'welcome',
            component: WelcomeComponent,
            data: { title: 'Bienvenido' },
          },
          {
            path: 'projectConfig',
            component: ProjectConfigComponent,
            data: { title: 'Ruta Critica' },
          },
          {
            path: 'budget',
            component: BudgetComponent,
            data: { title: 'Presupuesto' },
          },
          {
            path: 'dealMemo',
            component: DealMemoComponent,
            data: { title: 'Deal Memo' },
          },
          {
            path: 'purchaseOrder',
            component: PurchaseOrderComponent,
            data: { title: 'Orden de compra' },
          },
          {
            path: 'poGlobal',
            component: PoGlobalComponent,
            data: { title: 'Orden de compra global' },
          },
          {
            path: 'poGxc',
            component: PoGxcComponent,
            data: { title: 'Orden de compra GXC' },
          },
          {
            path: 'PO1',
            component: PurchaseOrder1Component,
            data: { title: 'Orden de compra V1' },
          },
          {
            path: 'consultPO1',
            component: ConsultPO1Component,
            data: { title: 'Consultar PO' },
          },
          {
            path: 'poReport',
            component: PoReportComponent,
            data: { title: 'Reporte de Ordenes de compra' },
          },
          {
            path: 'SOLV1',
            component: SolV1Component,
            data: { title: 'Solicitudes V1' },
          },
          {
            path: 'solReembolso',
            component: SolRembolsoComponent,
            data: { title: 'Solicitud Reembolso' },
          },
          {
            path: 'solGral',
            component: SolGralComponent,
            data: { title: 'Solicitud General' },
          },
          {
            path: 'consultSol',
            component: ConsultSolComponent,
            data: { title: 'Consultar Sol' },
          },

          {
            path: 'solGXC',
            component: SolGxcv2Component,
            data: { title: 'Solicitud GXC' },
          },
          {
            path: 'request',
            component: RequestComponent,
            data: { title: 'Solicitudes' },
          },
          {
            path: 'PriorRequest',
            component: PriorRequestComponent,
            data: { title: 'Prior Request' },
          },
          {
            path: 'checkRequest',
            component: CheckRequestComponent,
            data: { title: 'Consultar Solicitudes' },
          },
          {
            path: 'checkcomprobacion',
            component: CheckComprobacionComponent,
            data: { title: 'Check Comprobacion' },
          },
          {
            path: 'addAnexos',
            component: AddAnexosComponent,
            data: { title: 'Agregar Anexos' },
          },
          {
            path: 'provider',
            component: ProvidersComponent,
            data: { title: 'Proveedores' },
          },
          {
            path: 'costReport',
            component: CostReportComponent,
            data: { title: 'Reporte de costos' },
          },
          {
            path: 'catCtas',
            component: CtasProjectComponent,
            data: { title: 'Catalogo de cuentas' },
          },
          {
            path: 'consultOrdenCompra',
            component: ConsultOrdenCompraComponent,
            data: { title: 'Consultar Orde de compra' },
          },
          {
            path: 'comprobaciones',
            component: ComprobacionComponent,
            data: { title: 'Comprobaciones' },
          },
          {
            path: 'consultarComprobaciones',
            component: ChecksReportComponent,
            data: { title: 'Consultar Comprobaciones' },
          },
          {
            path: 'dealMemoReport',
            component: DealMemoReportComponent,
            data: { title: 'Deal Memo Report' },
          },
          {
            path: 'consultDealMemo',
            component: ConsultDealMemoComponent,
            data: { title: 'Consultar Deal Memos' },
          },
          {
            path: 'requestversion1',
            component: RequestVersion1Component,
            data: { title: 'Solicitudes1' },
          },
          // {
          //   path: 'disponible',
          //   component: DisponibleComponent,
          //   data: { title: 'Disponible' },
          // },
          {
            path: 'available',
            component: AvailableComponent,
            data: { title: 'Disponible' },
          },
          {
            path: 'estimadoCierre',
            component: EstimadoCierreComponent,
            data: { title: 'Estimado de cierre' },
          },
          {
            path: 'addRequestMassive',
            component: RequestMassiveComponent,
            data: { title: 'Agregar Solicitudes Masivas' },
          },
          {
            path: 'anexosMasivo',
            component: RequestAnexoComponent,
            data: { title: 'Agregar Anexos Masivo' },
          },
          {
            path: 'checkDealMemo',
            component: CheckDealMemoComponent,
            data: { title: 'Aprobar Deal Memo' },
          },
          {
            path: 'importador',
            component: ImportadorComponent,
            data: { title: 'Importador' },
          },
          {
            path: 'departamentos',
            component: DepartamentosComponent,
            data: { title: 'Departamentos' },
          },
          {
            path: 'gxc',
            component: SolGxcComponent,
            data: { title: 'Solicitudes GXC' },
          },
          {
            path: 'anexo',
            component: SolAnexoComponent,
            data: { title: 'Solicitud Anexo' },
          },
          {
            path: 'dealMemoMasive',
            component: DealMemoMasiveComponent,
            data: { title: 'Agregar Deal Memo Masivo' },
          },
          {
            path: 'aprobarSolicitud',
            component: ApproveRequestComponent,
            data: { title: 'Aprobar Solicitudes' },
          },
          {
            path: 'requestAproved',
            component: RequestAprovedComponent,
            data: { title: 'Aprobadas para pago' },
          },
          {
            path: 'reembolso',
            component: Reembolso2Component,
            data: { title: 'Reembolsos' },
          },
          {
            path: 'comprobacionExterno',
            component: ChecksComponent,
            data: { title: 'Comprobacion externo' },
          },
          {
            path: 'consultExterno',
            component: ComprobacionesComponent,
            data: { title: 'Consultar comprobaciones externo' },
          },
          {
            path: 'gxcExterno',
            component: GxcComponent,
            data: { title: 'Gastos por comprobar externo' },
          },
          {
            path: 'dealMemoExterno',
            component: DealMemoPublicComponent,
            data: { title: 'Deal Memo Externo' },
          },
          {
            path: 'reembolsoExterno',
            component: ReembolsoComponent,
            data: { title: 'Reembolsos Externos' },
          },
          {
            path: 'importadorExterno',
            component: ImportadorComponent,
            data: { title: 'Importador CFDI Externo' },
          },
          {
            path: 'proveedoresExternos',
            component: AproveProvidersComponent,
            data: { title: 'Proveedores Externos' },
          },
          {
            path: 'proveedoresNoVinculados',
            component: UnrelatedProviderComponent,
            data: { title: 'Proveedores No Vinculados' },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {
  constructor() {}
}

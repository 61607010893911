import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DealMemoPublicComponent } from 'src/app/website/deal-memo-public/deal-memo-public.component';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { AvisoPrivacidadComponent } from 'src/app/website/aviso-privacidad/aviso-privacidad.component';
import { ImportadorComponent } from 'src/app/website/importador/importador.component';
import { ChecksComponent } from 'src/app/website/checks/checks.component';
import { MenuComponent } from 'src/app/website/menu/menu.component';
import { ComprobacionesComponent } from 'src/app/website/comprobaciones/comprobaciones.component';
import { GxcComponent } from 'src/app/website/gxc/gxc.component';
import { FirmaComponent } from 'src/app/website/firma/firma.component';
import { ReembolsoComponent } from 'src/app/website/reembolso/reembolso.component';
import { PageNotFoundComponent } from 'src/app/pages/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: ':id/:id/dealMemo',
    component: DealMemoPublicComponent,
  },
  {
    path: 'firma/:id',
    component: FirmaComponent,
  },
  {
    path: 'coords',
    component: MenuComponent,
    children: [
      {
        path: 'comprobacion',
        component: ChecksComponent,
      },
      {
        path: 'consult',
        component: ComprobacionesComponent,
      },
      {
        path: 'gxc',
        component: GxcComponent,
      },
      {
        path: 'dealMemo',
        component: DealMemoPublicComponent,
      },
      {
        path: 'reembolso',
        component: ReembolsoComponent,
      },
      {
        path: 'importador',
        component: ImportadorComponent,
      },
    ],
  },
  {
    path: 'AvisoPrivacidad',
    component: AvisoPrivacidadComponent,
  },
  {
    path: 'importador',
    component: ImportadorComponent,
  },
  // {
  //   path: 'importador/:id',
  //   component: ImportadorComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebsiteRoutingModule {}
